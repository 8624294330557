<template>
  <div class="thumb">
    <pageHead title="我的点赞" />

    <div v-infinite-scroll="loadMore" class="thumb_content">
      <topic
        @like="like($event, index)"
        @favorite="favorite($event, index)"
        v-for="(item, index) in topicData"
        :key="item.id"
        :data="item"
        class="topicItem"
        @userInfoClick="handleToUserDetail(item)"
        @share="handleShare"
        @click="handleTopicClick"
        @originalClick="handleTopicClick"
        @follow="handleFollow(item, index)"
        @comment="handleComment"
        @handleNewActive="handleNewActive"
      />
      <no-more
        v-if="!listQueryParams.hasNextPage && topicData.length"
        text="没有更多数据"
        class="no-more"
      />
      <van-empty v-if="isLoad && !topicData.length" description="暂无数据" />
    </div>

    <commentToolDialog
      :show-dialog.sync="showDialog"
      :isAnonymousComment.sync="isAnonymousComment"
      v-model="commentContent"
      hide-relation
      hide-img
      @publish="handlePublishComment"
    />
  </div>
</template>

<script>
import {myThumbsup} from '@/api/thumbsup'
import {addThumbsup, removeThumbsup} from '@/api/thumbsup'
import {addFavorites, removeFavorites} from '@/api/favorites'
import {addComment} from '@/api/comment'

import pageHead from '@/components/pageHead'
import topic from '@/components/topicItem'
import NoMore from '@/components/uitls/NoMore'
import commentToolDialog from '@/components/commentToolDialog'
import {attAdd, followRemove} from '@/api/att'

const ENTITY_TYPE_ARTICLE = 2

export default {
  components: {
    pageHead,
    topic,
    NoMore,
    commentToolDialog
  },
  data() {
    return {
      pageNum: 1,
      pageSize: 20,
      topicData: [],
      listQueryParams: {
        pageNum: 1,
        pageSize: 10,
        hasNextPage: true
      },
      showDialog: false,
      isAnonymousComment: false,
      commentContent: '',
      isLoad: false
    }
  },
  methods: {
    loadMore() {
      this.loadList()
    },
    async handleFollow(data) {
      const {id, myFollow} = data
      if (myFollow) {
        await followRemove(id)
        data.commonFollowCount--
      } else {
        await attAdd(id)
        data.commonFollowCount++
      }
      data.myFollow = !myFollow
    },
    loadList() {
      let listQueryParams = this.listQueryParams
      listQueryParams.userId = this.$store.getters.user.user_id
      if (listQueryParams.hasNextPage) {
        myThumbsup(this.listQueryParams)
          .then((res) => {
            listQueryParams.hasNextPage = res.current < res.pages
            listQueryParams.pageNum = listQueryParams.pageNum + 1
            let resData = this.topicData
            res.records.forEach((item) => {
              let count = resData.filter(
                (innerItem) => innerItem.entityId === item.entityId
              )
              if (count.length === 0) {
                resData.push(item.entityDetail)
              }
            })
            this.topicData = resData
            console.log(this.topicData.length)
            this.isLoad = true
          })
          .catch(() => {
            this.isLoad = true
          })
      }
    },
    like(res, index) {
      console.log(res)
      // 取消点赞
      if (res.isThumbsUp) {
        removeThumbsup({
          entityId: res.articleId,
          entityType: 1
          // eslint-disable-next-line no-unused-vars
        }).then(() => {
          let data = this.topicData[index]
          this.topicData[index].isThumbsUp = !res.isThumbsUp
          let count = data.articleStatisticVO.thumbsUpCount - 1
          if (count < 0) count = 0
          data.articleStatisticVO.thumbsUpCount = count
        })
      } else {
        // 添加点赞
        addThumbsup({
          entityId: res.articleId,
          entityType: 1
        }).then(() => {
          let data = this.topicData[index]
          this.topicData[index].isThumbsUp = !res.isThumbsUp
          // if (!data.articleStatisticVO) {
          //   data.articleStatisticVO = {};
          // }
          data.articleStatisticVO.thumbsUpCount =
            data.articleStatisticVO.thumbsUpCount + 1
        })
      }
    },

    favorite(res, index) {
      console.log(res)
      // 取消收藏
      if (res.isFavorites) {
        removeFavorites({
          entityId: res.articleId,
          entityType: '1'
        }).then(() => {
          let data = this.topicData[index]
          this.topicData[index].isFavorites = !res.isFavorites
          let count = data.articleStatisticVO.favoritesCount - 1
          if (count < 0) count = 0
          data.articleStatisticVO.favoritesCount = count
        })
      } else {
        // 添加收藏
        addFavorites({
          entityId: res.articleId,
          entityType: '1'
        }).then(() => {
          let data = this.topicData[index]
          this.topicData[index].isFavorites = !res.isFavorites
          if (!data.articleStatisticVO) {
            data.articleStatisticVO = {}
          }
          data.articleStatisticVO.favoritesCount =
            data.articleStatisticVO.favoritesCount + 1
        })
      }
    },
    handleNewActive(item) {
      const index = this.topicData.findIndex((i) => {
        return i.articleId === item.articleId
      })
      console.log(this.topicData[index])
      this.topicData[index].articleVoteVO = item
    },
    handleSwiperClick(data) {
      const type = data.type
      if (type === 0 || type === 1) {
        this.$router.push({
          name: 'topicDetail',
          query: {id: data.articleId}
        })
      } else if (type === 2) {
        this.$router.push({name: 'forwardToWechat', query: {id: data.id}})
      } else if (type === 3) {
        window.location.href = data.content
      }
    },
    handleTopicClick({articleId} = {}) {
      // if(status === 0) {
      this.$router.push({name: 'topicDetail', query: {id: articleId}})
      // }
    },
    /**
     * 分享事件
     */
    handleShare({id: quoteId} = {}) {
      this.$router.push({name: 'createTopic', query: {quoteId}})
    },
    handleToUserDetail(item) {
      this.$router.push({name: 'userInfoDetail', params: {userId: item.userBasicVO.userId}})
    },
    handleComment(item) {
      this.showDialog = true
      this.commentParams = item
      console.log(this.commentParams)
    },
    async handlePublishComment() {
      const {
        commentContent: content,
        commentParams: {articleId: entityId},
        isAnonymousComment: isAnonymous
      } = this
      const entityType = ENTITY_TYPE_ARTICLE
      if (!content.trim()) {
        this.$notify({message: '评论内容不能为空', type: 'danger'})
        return
      }
      await addComment({content, entityId, entityType, isAnonymous})
      this.showDialog = false
      this.commentParams = {}
      this.commentContent = ''
      this.topicData = this.topicData.map((item) => {
        if (item.articleId !== entityId) {
          return item
        }
        const {articleStatisticVO} = item
        return {
          ...item,
          articleStatisticVO: {
            ...articleStatisticVO,
            commentCount: articleStatisticVO.commentCount + 1
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.thumb {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .thumb_content {
    position: relative;
    overflow-y: auto;
    height: calc(100vh - 52px);

    .topicItem {
      margin-top: 10px;
    }

    .no-more {
      margin-top: 10px;
    }
  }
}
</style>
